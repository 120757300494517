// angular
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  SetCartItems,
  FetchCart,
  FetchCartMenuItems,
} from '../actions/cart.actions';
import { iif, Observable, of, throwError } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { CartResponse } from '../models';
import { switchMap, map, tap, mergeMap } from 'rxjs/operators';
import { FetchHomeData, FetchMenuList } from '../actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {SweetAlertService } from '../services/sweet-alert-service';
import { debounceTime } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private http: HttpClient, private store: Store,private translate:TranslateService,private router:Router, private sweetAlert:SweetAlertService,) {}

  getCartItems(cart_id, organizationId) {
    const cartitems = {
      cartId: cart_id,
      organizationId: organizationId,
    };
    // console.warn(cart_id);
    this.store.dispatch(new SetCartItems(cartitems));
  }
  createNewCart(payload, cart_id) {
    let url: string = `${environment.cartApiUrl}`;
    let locationId = payload.locationId;
    if (cart_id) {
      url = `${environment.cartApiUrl}${cart_id}/menuItems`;
      payload = payload.menuItems;
    }
    // return Observable.create((observer) => {
    //   this.http.post(url, payload).subscribe(
    //     (result) => {
    //       if (!cart_id) cart_id = result['cart_id'];
    //       if (!cart_id) return observer.next(result);
    //       this.getCartDetails(cart_id, locationId).subscribe(
    //         (res) => {
    //           observer.next(res);
    //         },
    //         (error) => {
    //           observer.next(error);
    //         }
    //       );
    //     },
    //     (error) => {
    //       observer.next(error);
    //     }
    //   );
    // });
    return this.http.post(url, payload).pipe(
      tap( result => {
        if(!result['cart_id'] && result['status'] == 400){
          this.sweetAlert.toast('error', result['msg']);
        }
        if (!cart_id) cart_id = result['cart_id']
      }),
      mergeMap( response => iif(
        () => !!cart_id,
        this.getCartDetails(cart_id, locationId)
      ))
    )
  }
  updateCart(payload) {
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const url = `${environment.cartApiUrl}${payload['cartId']}/menuItem/${payload['menuItemId']}`;
    const data = { count: payload['count'] };

    if (payload['count'] === 0) {
      return Observable.create((observer) => {
        this.http.delete(url).subscribe(
          (deleteResult) => {
            this.getCartDetails(payload['cartId'], locationId).subscribe(
              (res) => {
                observer.next(res);
              },
              (error) => {
                observer.next(error);
              }
            );
          },
          (error) => {
            observer.next(error);
          }
        );
      });
    } else {
      return Observable.create((observer) => {
        this.http.put(url, data).subscribe(
          (result) => {
            this.getCartDetails(payload['cartId'], locationId).subscribe(
              (res) => {
                observer.next(res);
              },
              (error) => {
                observer.next(error);
              }
            );
          },
          (error) => {
            observer.next(error);
          }
        );
      });
    }
  }

  updateCount(payload) {
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const { cartId, menuItemId, count } = payload;
    const url = `${environment.cartApiv3Url}${cartId}/menuItem/${menuItemId}`;
    const deleteUrl = `${environment.cartApiUrl}${cartId}/menuItem/${menuItemId}`;
    if (count === 0)
      return this.http.delete(deleteUrl).pipe(
        switchMap((result) => {
          return this.getCartDetails(payload['cartId'], locationId);
        })
      );
    else
      return this.http.put(url, { count }).pipe(
        switchMap((result) => {
          if(result && result["_id"]){
          return this.getCartDetails(payload['cartId'], locationId);
          }
          else{
            if(result['status'] == 400){
              this.sweetAlert.toast('error', result['msg']);
              return this.getCartDetails(payload['cartId'], locationId);
            }
          }
        })
      );
  }

  returnAny(data){
    return data;
  }

  getCartDetails(cartId, locationId) {
    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    let lat = deliveryLocation ? deliveryLocation['loc'][1] : undefined;
    let long = deliveryLocation ? deliveryLocation['loc'][0] : undefined;
    let distance = localStorage.getItem('locationDistance')
      ? JSON.parse(localStorage.getItem('locationDistance'))
      : undefined;
    const paymentMethods = this.store.selectSnapshot(app => app.paymentMethods);
    let url = `${environment.cartApiv3Url}${cartId}?locationId=${locationId}`;

    if(paymentMethods && paymentMethods.selectedPayment)
      url += `&selectedPayment=${paymentMethods.selectedPayment}`;
    if (lat && long && distance !== undefined)
      url += `&lat=${lat}&long=${long}&distance=${distance * 1000}`;
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);

    if (futureDate)
      url += `&selectedTime=${new Date(futureDate).toISOString()}`;
    return this.http.get(url);
  }

  getCartItemDetails(payload) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    let url = `${environment.cartApiUrl}${payload.cartId}/organization/${Config.organizationId}?getOrgConfigs=true&avoidDistanceCheck=true`;
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);
    if (futureDate)
      url += `&selectedTime=${new Date(futureDate).toISOString()}`;
    if (selectedDelivery && selectedDelivery.textCode)
      url += `&delivery=${selectedDelivery.textCode}`;

    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    if(!deliveryLocation)
    deliveryLocation =this.store.selectSnapshot(
      (app) => app.address?.selectedAddress
    );
    if (selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE01' && deliveryLocation?.loc)
      url += `&lat=${deliveryLocation['loc'][1]}&lng=${deliveryLocation['loc'][0]}`;
    return this.http.get(url);
  }

  addTip(payload) {
    const cart = this.store.selectSnapshot((app) => app.cart.cart);
    const url = `${environment.cartApiUrl}${cart.cart_id}`;
    return this.http.put<CartResponse>(url, payload);
  }

  checkFuture(futureTime, locationId, cartId,payload?) {
    const deliveryTimeObj = payload.deliveryTimeObj;
    const selectedVenue = payload.selectedVenue
    const url = `${environment.mobileStoreApiUrl}stores/location/${locationId}/checkFuture`;
    const data = {
      futureDate: futureTime,
      offset: new Date().getTimezoneOffset(),
      deliveryTimeObj: deliveryTimeObj,
      selectedVenue: selectedVenue
    };
    if (cartId && !cartId['errorCode'])
      return this.http.post(url, data).pipe(
        switchMap((response) => {
          const { isBlackout } = response as any;
          if(isBlackout !== true){
            const url = `${environment.cartApiUrl}${cartId}/futurePickupTime`;
            const data = {
              pickupTime: futureTime,
              offset: new Date().getTimezoneOffset(),
              isFuture: true,
            };
            return this.http.put(url, data);
          }else{
            return throwError(response);
          }
        })
      );
    else return this.http.post(url, data);
  }

  fetchPrepTime(url: string) {
    return this.http.get(url);
  }

  updateCartItem(updatedItem, cartId) {
    const url = `${environment.cartApiUrl}${cartId}/menuItems`;
    return Observable.create((observer) => {
      this.http.put(url, updatedItem).subscribe(
        (result) => {
          if(result && result['status']== 200){
            observer.next(result);
          }
          else if(result && result['status']== 400){
            this.sweetAlert.toast('error', result['msg']);
          }
        },
        (error) => {
          observer.next(error);
        }
      );
    });
    
  }

  fetchETA() {
    let location = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let delivery = this.store.selectSnapshot(
      (app) => app.delivery.selectedDelivery
    );
    const currentCart = this.store.selectSnapshot(
      (app) => app.cart.cart
    );
    let deliveryAddress = undefined;
    try {
      deliveryAddress = JSON.parse(localStorage.getItem('selectedAdrsLocation'));
    } catch(e) {
      console.error('Error parsing deliveryAddress')
    }
    let deliveryTextCode= delivery && delivery.textCode ? delivery.textCode : undefined;
    if(deliveryTextCode){
        let url = `${environment.mobileStoreApiUrl}stores/location/${location._id}/prep?deliveryMethod=${deliveryTextCode}`;

        if(currentCart?.cart_id)
          url += `&cartId=${currentCart.cart_id}`;
        if(deliveryAddress && deliveryAddress.loc && Array.isArray(deliveryAddress.loc)) {
          const [ long, lat ] = deliveryAddress.loc;
          url += `&lat=${lat}&long=${long}`;
        }
        
        return this.http.get(url);
    } else {
      console.error('Error fetching card or delivery details',deliveryTextCode, currentCart);
      return of('Error fetching card or delivery details');
    }
  
  }

  futureDateChanged() {
    const state = this.store.selectSnapshot((app) => app);
    const { home, menulist, cart } = state;
    if (home) this.store.dispatch(new FetchHomeData());
    if (menulist && menulist.menuList) this.store.dispatch(new FetchMenuList());
    if (cart && cart.cart) {
      this.store.dispatch(new FetchCart());
      this.store.dispatch(new FetchCartMenuItems({}));
    }
  }

  updateCartDetails(body,cartId) {
    const url = `${environment.cartApiUrl}${cartId}`;
    return this.http.put(url, body);
  }

  updateCartComboCount(body,cart,comboId) {
    let url = `${environment.cartApiUrl}${cart?.cart.cart_id}/comboModule/${comboId}`;
    if(cart?.futureDate)
    url += `?isFuture=true&pickupTime=${cart.futureDate}`;
    else
    url += `?isFuture=false`;
    return this.http.put(url, body);
  }

  deleteCartCombo(cart,comboId) {
    let url = `${environment.cartApiUrl}${cart?.cart.cart_id}/comboModule/${comboId}`;
    return this.http.delete(url);
  }

  getFutureTimes(locationId,deliveryMethod,selectedVenue,isClassRoomOrder) {
    let url = `${environment.mobileStoreApiv3Url}stores/${locationId}/futureTimes?timezone=${-new Date().getTimezoneOffset()}&language=${this.translate.currentLang}`;
    if (this.router.url !== '/locations' && deliveryMethod){
      url += `&deliveryMethod=${deliveryMethod.textCode}`;
      if(deliveryMethod?.textCode == 'DELIVERYTYPE10' ){
        if(selectedVenue?.allowSchedule && selectedVenue?.shifts?.length > 0){
          url += `&selectedRoom=${selectedVenue._id}`;
        }
      }
    } else if(this.router.url == '/locations'){
      if(isClassRoomOrder && selectedVenue?.allowSchedule && selectedVenue?.shifts?.length > 0){
        url += `&deliveryMethod=DELIVERYTYPE10`;
        url += `&selectedRoom=${selectedVenue._id}`;
      } else if(deliveryMethod?.textCode){
        url += `&deliveryMethod=${deliveryMethod.textCode}`;
      }
    }
    return this.http.get(url);
  }

  addOrderLevelModifier(cartId,newOrderLevelModifier){
    const url = `${environment.cartApiUrl}${cartId}/orderLevelModifier`;
    return this.http.post(url, newOrderLevelModifier);
  }
  removeOrderLevelModifier(cartId,id){
    const url = `${environment.cartApiUrl}${cartId}/orderLevelModifier/${id}`;
    return this.http.delete(url);
  }
}
